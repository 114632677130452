//export const BASE_URL = 'https://127.0.0.1:8000/'
//export const BASE_URL = 'http://localhost/'
//export const BASE_URL = 'https://api.fibme.fr/'
export const BASE_URL = 'https://admin.jobertech.com/'

export const BASE_URL_UP = BASE_URL + 'api/'
export const STRIPE_URL = BASE_URL
export const FILE_URL = BASE_URL + 'file/'
//export const FRONT_URL = 'https://front.fibme.fr/'
export const FRONT_URL = 'https://www.jobertech.com/'
//export const STRIPE_KEY = 'pk_test_51Lxs90FckL3ExSvQylfgGe5lehUlFm1383cSGbH1LO3asplC4G9LYraES4v7vAIMXJRt7gpS8GAhnYTDpbJTsvuv00HhhNYDRN'
export const STRIPE_KEY = 'pk_live_51Lxs90FckL3ExSvQtCowxtyF1kmRjroy6UBityl3KP62PhN6UNaFfNtH9nxUEBiW8IygwZoOxcxYMASrAyY0ixrz00cW5qNBnU'

export const JITSI_APP_ID =
  'vpaas-magic-cookie-06ee6bdc55d149d9823f430b5cb83831'
/*
export const phoneRegExp = /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;
*/

export const phoneRegExp = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/

/*export const phoneRegExp = new RegExp(/"^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$"/gmi);*/
export const passwordRegExp =
  /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,100}$/

export function unserializeLocal(serializedString) {
  if (serializedString === 'a:0:{}' || serializedString === '') return ''
  // Extract text between double quotes using regular expression
  const extractedValues = serializedString
    .match(/"(.*?)"/g)
    .map((value) => value.replace(/"/g, ''))

  return extractedValues
}
export function unserializeCompetenceLocal(serializedString) {
  if (serializedString === 'a:0:{}' || serializedString === '') return '';

  // Regular expression to match strings and numbers
  const regex = /"(.*?)"|i:(\d+);/g;
  const extractedValues = [];
  let match;

  while ((match = regex.exec(serializedString)) !== null) {
    if (match[1] !== undefined) {
      // Matched a string
      extractedValues.push(match[1]);
    } else if (match[2] !== undefined) {
      // Matched a number
      extractedValues.push(parseInt(match[2], 10));
    }
  }

  return transformArray(extractedValues);
}

export function transformArray(inputArray) {
  const result = [];
  let currentObj = {};

  for (let i = 0; i < inputArray.length; i++) {
    const item = inputArray[i];

    if (typeof item === 'string' && item === 'competence') {
      // We found 'competence', the next item should be its value
      currentObj['competence'] = inputArray[++i];
    } else if (typeof item === 'string' && item === 'niveau') {
      // We found 'niveau', the next item should be its value
      currentObj['niveau'] = inputArray[++i];

      // Once we have both competence and niveau, push the currentObj to result
      result.push(currentObj);

      // Reset currentObj for the next set of data
      currentObj = {};
    }
  }

  return result;
}

export function truncate(str) {
  if(str) {
    return str.length > 150 ? str.substring(0, 150) + '...' : str
  }
  return ''
}

export function debounce(func, delay) {
  let timeoutId
  return function (...args) {
    clearTimeout(timeoutId)
    timeoutId = setTimeout(() => func.apply(this, args), delay)
  }
}

export function fileSize(size) {
  let convertSize = size / 1024 ** 2
  return convertSize.toFixed(3)
}

export function extractYouTubeVideoId(url) {
  // Regular expression to match YouTube video URLs
  var regex =
    /(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/

  // Try to match the regex with the provided URL
  var match = url.match(regex)

  // If a match is found, return the video ID (group 1 in the regex)
  return match && match[1] ? match[1] : null
}

export function hasPermission(user, permission) {
  return user.allowedPermissions.includes(permission)
}
