import RegisterCard from '../../../components/cards/RegisterCard'
import React, { useEffect, useState } from 'react'
import {
  Box,
  CircularProgress,
  FormControl,
  FormLabel,
  Grid,
  styled,
  Typography,
} from '@mui/material'
import { PasswordInputBasic } from '../../../components/inputs'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import '../../../styles/components/_grids.scss'
import lightTheme from '../../../styles/theme/lightTheme'
import { BorderButton, CustomButton } from '../../../components/buttons'
import Api from '../../../services/api.service'
import { handlingErrors } from '../../../utils'
import { passwordRegExp } from '../../../config'
import '../../../styles/components/_errors.scss'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { userLogout } from '../../../store/slices/userAuthReducer'
import StripeAppTest from '../../../components/Stripe/StripeAppTest'
import { useNavigate } from 'react-router-dom'
import { faCircleCheck } from '@fortawesome/pro-regular-svg-icons'

const schema = yup.object({
  password: yup
    .string()
    .matches(
      passwordRegExp,
      'Votre mot de passe doit comporter au moins 6 caractères et doit contenir au moins une lettre, un chiffre et un caractère spécial (!@#$%^&*).'
    )
    .required('Merci de créer votre mot de passe.'),
  confirmPassword: yup
    .string()
    .required('Merci de ressaisir votre mot de passe.')
    .oneOf([yup.ref('password')], 'Le mot de passe ne correspond pas.'),
  cv: yup.mixed(),
  /*.required("Merci de télécharger votre cv")*/
})

const InputContainer = styled(FormControl)(({ theme }) => ({
  rowGap: '5px',
}))

const CustomFormLabel = styled(FormLabel)(({ theme }) => ({
  fontFamily: 'Century Gothic Bold',
  fontSize: 10,
  lineHeight: '140%',
  letterSpacing: '0.2px',
  color: lightTheme.palette.text.primary,

  [lightTheme.breakpoints.up('md')]: {
    fontSize: 12,
  },

  [lightTheme.breakpoints.up('lg')]: {
    fontSize: 14,
  },
}))

const StepsCandidat = ({ token }) => {
  const [currentStep, setCurrentStep] = useState(0)
  const [loading, setLoading] = useState(false)
  const [snapShot, setSnapShot] = useState(null)
  /* const [id, setId] = useState(null) */
  const [openCard, setOpenCard] = useState(false)
  /* const [file, setFile] = useState('') */
  const [password, setPassword] = useState('')
  const [errorLength, setErrorLength] = useState(false)
  const [errorNumber, setErrorNumber] = useState(false)
  const [errorSpecialCarter, setErrorSpecialCarter] = useState(false)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) })

  useEffect(() => {
    const containsNumber = /\d/.test(password)
    const containsSpecialCharacter = /[!@#$%^&*]/.test(password)
    setErrorLength(password.length > 6)
    setErrorNumber(containsNumber)
    setErrorSpecialCarter(containsSpecialCharacter)
  }, [password])

  function onClose() {
    setCurrentStep(0)
  }

  function onSuccess() {
    toast.success('Félicitations ! Votre compte est confirmé.', {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'light',
      icon: ({ theme, type }) => (
        <FontAwesomeIcon
          icon={faCircleCheck}
          fontSize={25}
          color={lightTheme.palette.success.main}
        />
      ),
    })
    navigate('/auth/se-connecter')
  }

  function nextStep() {
    setCurrentStep(currentStep + 1)
  }

  const submitFinishInscription = async (data) => {
    setLoading(true)

    //console.log('hello submit')

    const form = new FormData()
    form.append('password', data.password)
    //form.append('cv', file ? file : null)
    form.append('cv', null)

    //console.log('form', form)

    const result = await Api.finalisationInscription.postInscription(
      token,
      form
    )
    const handling = handlingErrors(result)

    //console.log('result', result)

    if (handling.status === 401) {
      //console.log('erreur post')
      dispatch(userLogout())
      return
    }

    if (handling.ok) {
      //console.log('ça marche post')
      const res = handling.data
      //setId(res.id)
      setSnapShot(res)
      setOpenCard(true)
      setLoading(false)
      if (res.userOffres.length === 0) {
        toast.success('Félicitations ! Votre compte est confirmé.', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleCheck}
              fontSize={25}
              color={lightTheme.palette.success.main}
            />
          ),
        })
        navigate('/auth/se-connecter')
      }
    } else {
      //console.log('erreur post')
      setLoading(false)
    }
  }

  /* useEffect(() => {
    console.log(snapShot);
  }, [id])*/

  return (
    <>
      <RegisterCard>
        <form style={{ height: '100%', position: 'relative' }}>
          <Grid container spacing={2} className={'gridContainerRegister'}>
            {!openCard ? (
              <>
                <Grid item xs={12}>
                  <InputContainer fullWidth>
                    <CustomFormLabel>
                      Choisissez un mot de passe
                    </CustomFormLabel>
                    {/*<TextInputClassic
                      placeholder={"Mot de passe"}
                      width={"100%"}
                      register={{...register("password")}}
                      type={"password"}
                    />*/}

                    <PasswordInputBasic
                      placeholder={'Mot de passe'}
                      register={{ ...register('password') }}
                      onChange={(e) => setPassword(e.target.value)}
                    />

                    {errors.password && (
                      <Typography className={'error-message'}>
                        {errors.password.message}
                      </Typography>
                    )}
                    <div>
                      <ul style={{ fontSize: 12 }}>
                        <li
                          style={{
                            textDecoration: errorLength
                              ? 'line-through'
                              : 'none',
                          }}
                        >
                          Au moins 6 caractères
                        </li>
                        <li
                          style={{
                            textDecoration: errorNumber
                              ? 'line-through'
                              : 'none',
                          }}
                        >
                          Un chiffre
                        </li>
                        <li
                          style={{
                            textDecoration: errorSpecialCarter
                              ? 'line-through'
                              : 'none',
                          }}
                        >
                          Un caractère spécial (!@#$%^&*)
                        </li>
                      </ul>
                    </div>
                  </InputContainer>
                </Grid>
                <Grid item xs={12}>
                  <InputContainer fullWidth>
                    <CustomFormLabel>
                      Ressaisissez le mot de passe
                    </CustomFormLabel>
                    {/*<TextInputClassic
                      placeholder={"Confirmer mot de passe"}
                      width={"100%"}
                      register={{...register("confirmPassword")}}
                      type={"password"}
                    />*/}

                    <PasswordInputBasic
                      placeholder={'Confirmer mot de passe'}
                      register={{ ...register('confirmPassword') }}
                    />

                    {errors.confirmPassword && (
                      <Typography className={'error-message'}>
                        {errors.confirmPassword.message}
                      </Typography>
                    )}
                  </InputContainer>
                </Grid>
              </>
            ) : (
              <>
                {currentStep === 0 && (
                  <>
                    <Grid item xs={12}>
                      <Typography
                        variant={'subtitle2'}
                        textTransform={'uppercase'}
                      >
                        Résumé de l'offre proposé :
                      </Typography>
                    </Grid>

                    <Grid item xs={12}>
                      <Typography variant={'h5'} textTransform={'uppercase'}>
                        {snapShot.userOffres[0].offre.titre}
                      </Typography>
                      <Typography
                        variant={'subtitle2'}
                        textTransform={'uppercase'}
                      >
                        {snapShot.userOffres[0].offre.sousTitre}
                      </Typography>
                    </Grid>

                    <Grid item xs={12}>
                      <Typography variant={'subtitle2'}>Contenu :</Typography>
                      <ul>
                        {snapShot.userOffres[0].offre.points[1]?.length > 0 && (
                          <li>{snapShot.userOffres[0].offre.points[1]}</li>
                        )}

                        {snapShot.userOffres[0].offre.points[2]?.length > 0 && (
                          <li>{snapShot.userOffres[0].offre.points[2]}</li>
                        )}

                        {snapShot.userOffres[0].offre.points[3]?.length > 0 && (
                          <li>{snapShot.userOffres[0].offre.points[3]}</li>
                        )}

                        {snapShot.userOffres[0].offre.points[4]?.length > 0 && (
                          <li>{snapShot.userOffres[0].offre.points[4]}</li>
                        )}
                      </ul>
                    </Grid>

                    <Grid item xs={12}>
                      <Typography variant={'subtitle2'}>Prix :</Typography>
                      <ul>
                        <li>
                          {(
                            snapShot.userOffres[0].offre.prix *
                            JSON.parse(sessionStorage.getItem('currency'))
                              .currentRate
                          ).toFixed(2)}
                          {''}
                          {sessionStorage.getItem('currency')
                            ? JSON.parse(sessionStorage.getItem('currency'))
                                .devise
                            : 'CHF'}
                        </li>
                      </ul>
                    </Grid>
                  </>
                )}
              </>
            )}

            <Box
              sx={{
                width: 'calc(100% - 16px)',
                display: 'flex',
                flexDirection: 'row',
                columnGap: '15px',
                justifyContent: 'flex-end',
                alignItems: 'end',
                marginLeft: '16px',
              }}
            >
              {currentStep > 0 && (
                <Box width={'50%'} zIndex={1}>
                  <BorderButton
                    type={'basic'}
                    displayIconRight={'none'}
                    handleClick={() => setCurrentStep(currentStep - 1)}
                  >
                    Étape précédente
                  </BorderButton>
                </Box>
              )}
              <Box width={'50%'}>
                {loading ? (
                  <CircularProgress />
                ) : (
                  <>
                    {!openCard ? (
                      <CustomButton
                        handleClick={handleSubmit(submitFinishInscription)}
                      >
                        Valider
                      </CustomButton>
                    ) : currentStep === 0 ? (
                      <CustomButton handleClick={nextStep}>
                        Paiement
                      </CustomButton>
                    ) : (
                      <></>
                    )}
                  </>
                )}
              </Box>
            </Box>
          </Grid>
        </form>

        {currentStep === 1 && (
          <>
            <Grid
              item
              xs={12}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                rowGap: '50px',
                position: 'absolute',
                top: '20px',
                width: 'calc(100% - 47px)',
                height: 'calc(100% - 47px)',
              }}
            >
              <Typography variant={'subtitle2'} textTransform={'uppercase'}>
                Tarif de l’offre {snapShot?.userOffres[0].offre.titre}{' '}
                {snapShot.userOffres[0].offre.sousTitre} -{''}
                {(
                  snapShot.userOffres[0].offre.prix *
                  JSON.parse(sessionStorage.getItem('currency')).currentRate
                ).toFixed(2)}
                {''}
                {sessionStorage.getItem('currency')
                  ? JSON.parse(sessionStorage.getItem('currency')).devise
                  : 'CHF'}{' '}
                userId: {snapShot?.id} offreId:{' '}
                {snapShot?.userOffres[0].offre.id}
              </Typography>
              <Box>
                <StripeAppTest
                  userId={snapShot?.id}
                  offreId={snapShot?.userOffres[0].id}
                  offrePrice={(
                    snapShot?.userOffres[0].offre.prix *
                    JSON.parse(sessionStorage.getItem('currency')).currentRate
                  ).toFixed(2)}
                  onSuccess={onSuccess}
                  onClose={onClose}
                />
              </Box>
            </Grid>
          </>
        )}
      </RegisterCard>
    </>
  )
}

export default StepsCandidat
