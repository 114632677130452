import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Select, styled, MenuItem } from '@mui/material'
import lightTheme from '../../styles/theme/lightTheme'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons'

const CustomSelect = styled(Select)(({ theme }) => ({
  width: '100%',
  height: 45,
  borderWidth: 0,
  borderRadius: 0,
  backgroundColor: lightTheme.palette.primary.main,
  color: '#FFFFFF',
  fontFamily: 'Century Gothic Bold',
  textTransform: 'uppercase',
  cursor: 'pointer',
  position: 'relative',
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  '& .css-1irc8sz-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper': {
    background: 'red',
  },
  [theme.breakpoints.up('lg')]: {
    height: 50,
  },
}))

export default function SelectLangages() {
  const { t, i18n } = useTranslation()
  const [langage, setLangage] = useState(localStorage.getItem('lang') ? localStorage.getItem('lang') : 'lang')
  const handleLangChange = (evt) => {
    const lang = evt.target.value
    setLangage(lang)
    i18n.changeLanguage(lang)
    localStorage.setItem('lang', lang)
  }

  return (
    <CustomSelect
      onChange={handleLangChange}
      /* value={i18n.language} */
      defaultValue={langage}
      IconComponent={() => (
        <FontAwesomeIcon
          icon={faChevronDown}
          style={{
            position: 'absolute',
            right: '28px',
            display: 'none'
          }}
          onChange={handleLangChange}
        />
      )}
    ><MenuItem sx={{ display: 'none' }} value="lang">
    {t('profile.language')} <FontAwesomeIcon
          icon={faChevronDown}
          style={{
            position: 'absolute',
            right: '25px',
            marginTop: '2px'
          }}
          onChange={handleLangChange}
        />
  </MenuItem>
      <MenuItem sx={{ background: i18n.language === 'fr' ? '#808bf814' : '' }} value="fr">{t('header.french')}</MenuItem>
      <MenuItem sx={{ background: i18n.language === 'en' ? '#808bf814' : '' }} value="en">{t('header.english')}</MenuItem>
    </CustomSelect>
  )
}
